:root {
  --color-teal: #00FFD1;
  --color-blue: #1e8fb7;
  --color-green: #15a18e;
  --color-grey:  #678b9b;
  --color-yellow: #FFBD53;
  
  /* --color-green: #39b7a0; */
  /* --color-blue: #71c5e3; */
 
  --foreground-color: white;
  --rounded: 15px;
  --semi-rounded: 9px;
  --page-width: 1180px;
  --content-width: 960px;
  --bodyDark: #2c2c2c;

  --page-spacing: 64px;

  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;

  /* --inter: "Inter", sans-serif; */
  --h1: 900 48px/54px var(--bodyFont);
  --h2: 900 34px/36px var(--bodyFont);
  --h3: 700 20px/28px var(--bodyFont);
  --body: 600 18px/24px var(--bodyFont);
  --small: 400 16px/24px var(--bodyFont);
  --mobile: 640px;

  --box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;


  --yellow-blue-light: linear-gradient(
    90deg,
    rgba(252, 252, 212, 0.38) 0.1%,
    #c2e0ea 100%
  );
  --pale-blue-light: background: linear-gradient(220deg, #E0E0E0 32.07%, #CFE4EC 77.65%);
  --orange-light :rgba(249, 161, 100, 0.38);
  --color-lightgoldenrodyellow-100: #c0c09a;
  --color-lightgoldenrodyellow-200: #b6b690;
}

@media (max-width: 640px) {
  :root {
    --h3: 500 18px/22px var(--bodyFont);
    --body: 500 16px/20px var(--bodyFont);
    --small: 400 14px/16px var(--bodyFont);

     --page-spacing: 42px 24px;
  }
}

@media (max-width: 380px) {
  :root {
     --page-spacing: 32px 16px;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  color-scheme: dark;
  font-family: var(--bodyFont), sans-serif;
  -webkit-font-smoothing: antialiased;
   scroll-behavior: smooth !important;
}

body {
  max-width: 100vw;
  min-width: 360px;
  overflow-x: hidden;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

body {
  color: var(--foreground-color);
  background: #f3f8fb;
  /* font-size: 20px;
  line-height: 24px;
  font-weight: 600; */
    font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  /* display: block;
  margin-bottom: var(--spacing-l); */
   font-size: 48px;
 line-height: 48px;
 font-weight: 900;
}

h2 {
 font-size: 42px;
 line-height: 42px;
 font-weight: 900;
}

h3,
.subheading {
  /* font: var(--h3); */
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

/* p {
  margin-bottom: 16px;
} */

ul,
ol {
  margin-bottom: 16px;
  margin-left: 16px;
}

mark {
  color: var(--color-teal);
  background-color: transparent;
}


.hidden {
  display: none;
}

